import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css as styledCSS } from "styled-components";

import { item, ItemIcon } from "./Dropdown.styles";

export const Item = styled(DropdownMenu.Item)<{ $isSelected?: boolean }>`
  ${item};

  ${({ $isSelected }) =>
    $isSelected &&
    styledCSS`
      color: var(--mode-text-primary-default);
      ${ItemIcon} {
        fill: var(--mode-text-primary-default);
      }

      :hover {
        color: var(--mode-text-primary-default);
        ${ItemIcon} {
          fill: var(--mode-text-primary-default);
        }
      }
    `}
`;
